<template>
    <div class="serviceRecord">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    服务记录
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='list_content'>
            <div class='list'>
                <div class='list_title'>
                    第1次服务
                </div>
                <div class='nurse_cont'>
                    <div class='nurse'>护士姓名</div>
                    <div class='nurse_name'>王娟</div>
                    <div class='order_btn' @click='router.push({
                        name : "evaluation"
                    })'>去评价</div>
                </div>
                <div class='nurse_cont'>
                    <div class='nurse'>服务时间</div>
                    <div class='nurse_name'>2021-12-12</div>
                </div>
            </div>

        </div>
        
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'



export default {
  name: 'serviceRecord',
  components: {
    
  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let payShow = ref(false);
    let concelShow = ref(false);
    let checked = ref(1);
    const active = ref(0);
    return {
        router,
        checked,
        active,
        payShow,
        concelShow
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .list_content{
        padding:20px;
    }
    .list_title{
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313131;
    }
    .list{
        padding:30px 40px;
        border-bottom: 1px solid #F2F2F2;
    }
    .nurse_cont{
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333; 
        display: flex;
        justify-content: flex-start;
        margin-top:20px;
        margin-bottom:20px;
        position: relative;
        .nurse{
            color: #919090;
            width: 200px;
        }
    }
    .order_btn{
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        height: 50px;
        padding:0 22px;
        line-height: 50px;
        border-radius: 25px;
        border: 1px solid #999999;
        margin-left:20px;
        position: absolute;
        right: 0;
        top:-10px;
    }



</style>
